import { gql, useLazyQuery } from "@apollo/client";

export const QUERY_CREATE_STREAMING_URL = gql`
  query connectEnvironment($environmentId: ID!) {
    connectEnvironment(input: { environmentId: $environmentId }) {
      url
    }
  }
`;

export function useConnectEnvironmentMutation() {
  const [
    connectEnvironment,
    {
      data: connectionUrl,
      loading: isLoadingConnectionUrl,
      error: errorConnectionUrl
    }
  ] = useLazyQuery(QUERY_CREATE_STREAMING_URL, {
    context: { clientName: "interactive" }
  });

  return {
    connectEnvironment,
    connectionUrl,
    isLoadingConnectionUrl,
    errorConnectionUrl
  };
}
