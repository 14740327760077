import { gql, useMutation } from "@apollo/client";

export const MUTATION_SUBMIT_EXECUTION_JOB = gql`
  mutation submitJobFromIE(
    $name: String!
    $imageId: ID!
    $activityId: ID!
    $actionType: ActionType!
    $qualityCheckStatus: QualityCheckStatus
    $entrypoint: InteractiveFileInput!
    $workingDir: String!
    $description: String
    $inputs: [InteractiveFileInput!]
    $command: String
    $environmentVariables: [EnvironmentVariableInput]
    $timeout: Int
    $memory: Int
    $vCPU: Int
    $numOfNodes: Int
    $environmentId: ID!
  ) {
    submitExecutionJob(
      input: {
        name: $name
        imageId: $imageId
        activityId: $activityId
        actionType: $actionType
        qualityCheckStatus: $qualityCheckStatus
        entrypoint: $entrypoint
        workingDir: $workingDir
        description: $description
        inputs: $inputs
        environmentVariables: $environmentVariables
        command: $command
        timeout: $timeout
        memory: $memory
        vCPU: $vCPU
        numOfNodes: $numOfNodes
        environmentId: $environmentId
      }
    ) {
      id
    }
  }
`;

export function useSubmitExecutionJobFromInteractiveMutation() {
  const [
    submitJobFromIE,
    {
      data: executionJobIEData,
      loading: isSubmitExecutionJobIELoading,
      error: errorSubmitExecutionJobIE
    }
  ] = useMutation(MUTATION_SUBMIT_EXECUTION_JOB, {
    context: { clientName: "interactive" }
  });

  return {
    submitJobFromIE,
    executionJobIEData,
    isSubmitExecutionJobIELoading,
    errorSubmitExecutionJobIE
  };
}
