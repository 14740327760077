import { useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";

export const LIST_INTERACTIVE_FILES = gql`
  query listInteractiveFiles(
    $environmentId: ID!
    $limit: Int!
    $cursor: String
  ) {
    listInteractiveFiles(
      input: { environmentId: $environmentId, limit: $limit, cursor: $cursor }
    ) {
      files {
        repoName
        path
      }
      nextCursor
    }
  }
`;

export function useQueryListInteractiveFilesQuery(
  limit: number = 10000,
  cursor: string | null = null
) {
  const [
    getListInteractiveFiles,
    {
      data: interactiveFiles,
      loading: isLoadingInteractiveFiles,
      error: errorLoadingInteractiveFiles,
      fetchMore: fetchMoreInteractiveFiles
    }
  ] = useLazyQuery(LIST_INTERACTIVE_FILES, {
    variables: {
      limit,
      cursor
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    context: { clientName: "interactive" }
  });

  const listMoreInteractiveFiles = (limit: number, nextCursor: string) => {
    fetchMoreInteractiveFiles({
      variables: {
        limit,
        cursor: nextCursor
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        let currentFiles = prevResult?.listInteractiveFiles?.files || [];
        let newFiles = fetchMoreResult?.listInteractiveFiles.files || [];
        fetchMoreResult.listInteractiveFiles = {
          ...fetchMoreResult.listInteractiveFiles,
          files: [...currentFiles, ...newFiles]
        };

        return fetchMoreResult;
      }
    });
  };

  useEffect(() => {
    if (interactiveFiles && interactiveFiles.listInteractiveFiles.nextCursor) {
      listMoreInteractiveFiles(
        limit,
        interactiveFiles.listInteractiveFiles.nextCursor
      );
    }
  }, [interactiveFiles, limit]);

  return {
    getListInteractiveFiles,
    interactiveFiles: interactiveFiles?.listInteractiveFiles?.files || [],
    isLoadingInteractiveFiles,
    errorLoadingInteractiveFiles
  };
}
