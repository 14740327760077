import { gql, useMutation } from "@apollo/client";

export const MUTATION_CREATE_ENVIRONMENT = gql`
  mutation createEnvironment(
    $activityId: ID!
    $imageId: String!
    $instanceTypeId: String!
    $environmentName: String
    $importPaths: [InteractiveFileInput!]
    $actionId: ID
    $schedule: String
  ) {
    createEnvironment(
      input: {
        activityId: $activityId
        imageId: $imageId
        instanceTypeId: $instanceTypeId
        environmentName: $environmentName
        importPaths: $importPaths
        actionId: $actionId
        schedule: $schedule
      }
    ) {
      environmentId
    }
  }
`;

export function useCreateEnvironmentMutation() {
  const [
    createEnvironment,
    {
      data: environmentCreated,
      loading: isLoadingEnvironmentCreated,
      error: errorEnvironmentCreated
    }
  ] = useMutation(MUTATION_CREATE_ENVIRONMENT, {
    context: { clientName: "interactive" }
  });

  return {
    createEnvironment,
    environmentCreated,
    isLoadingEnvironmentCreated,
    errorEnvironmentCreated
  };
}
