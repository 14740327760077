import forge from "node-forge";
import { Maybe } from "../backend/types";

// Module that deals with conversations and comments within the context of a PMX activity
export const quillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    ["link", "image"],
    ["clean"]
  ]
};

export const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image"
];

export const INTERACTIVE_ALERT_WARNING =
  "Are you sure you want to delete the environment? All interactive data will be lost!";

export const MAX_REPLY_COMMENT_STRING_LENGTH = 150;
export const SECONDS_TO_WAIT_FOR_REPO = 5 * 60;
export const MAXIMUM_CLEAR_PASSWORD = 50;

/*** Action Source Enum ***/
export enum ActionSource {
  EXTERNAL_SYSTEM = "External System",
  PHIL_BASE_SYSTEM = "PHIL Base System",
  PHIL_INTERACTIVE_ENVIRONMENT = "PHIL Interactive Environment"
}
export declare type ActionSourceType = keyof typeof ActionSource;
export const getActionSourceKeyFromValue = (value: string) =>
  Object.keys(ActionSource)[
    Object.values(ActionSource).indexOf(value as unknown as ActionSource)
  ];

export enum QualityCheckStatus {
  QCED = "QCed",
  QC_READY = "QC Ready",
  QC_FAILED = "QC Failed"
}
export declare type QualityCheckStatusType = keyof typeof QualityCheckStatus;
export const getQualityCheckStatusKeyFromValue = (value: string | null) =>
  value
    ? Object.keys(QualityCheckStatus)[
        Object.values(QualityCheckStatus).indexOf(
          value as unknown as QualityCheckStatus
        )
      ]
    : null;

/*** Action Type Enum ***/
export enum ActionType {
  DOWNLOAD = "Download",
  SWAN_IMPORT = "SWANImport",
  UPLOAD = "Upload",
  ADF_CREATION = "ADF Creation",
  ARCHIVE = "Compress",
  UNCOMPRESS = "Uncompress",
  MODSPACE_EXPORT = "ModSpaceExport",
  MODSPACE_IMPORT = "ModSpaceImport",
  HIDE = "Hide",
  RENAME = "Rename",
  COPY = "Copy",
  MOVE = "Move",
  PLOT_CREATION = "Plot Creation",
  REPORT_CREATION = "Report Creation",
  MODEL_EXECUTION = "Model Execution",
  OTHER_ACTION = "Other Action",
  SPECTRUM_IMPORT = "SpectrumImport",
  SPM_SVN_EXPORT = "SpmSvnExport",
  SPM_SVN_IMPORT = "SpmSvnImport",
  RESTORE = "Restore"
}
export declare type ActionTypeType = keyof typeof ActionType;
export const getActionTypeKeyFromValue = (value: string | undefined) =>
  value
    ? Object.keys(ActionType)[
        Object.values(ActionType).indexOf(value as unknown as ActionType)
      ]
    : undefined;

/*** Other Functions ***/
export function removeTags(str: string | undefined | null): string {
  if (str === null || str === undefined || str === "") return "";
  else str = str.toString();

  // Regular expression to identify HTML tags in the input string. Replacing the identified HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "");
}

export function shortenString(
  str: string,
  maxLength: number = MAX_REPLY_COMMENT_STRING_LENGTH
): string {
  if (str.length <= maxLength) {
    return str;
  } else {
    return str.slice(0, maxLength - 3) + "...";
  }
}

export function shortenStringMiddle(
  str: string,
  maxLength: number = MAX_REPLY_COMMENT_STRING_LENGTH
): string {
  if (str.length <= maxLength) {
    return str;
  } else {
    return (
      str.slice(0, maxLength / 2) +
      " ... " +
      str.slice(str.length - maxLength / 2 - 5, str.length)
    );
  }
}

export const encryptStringWithRsaPublicKey = (toEncrypt: string): string => {
  if (!process.env.REACT_APP_PUBLIC_KEY_PASSWORD) {
    throw new Error("REACT_APP_PUBLIC_KEY_PASSWORD is not setup");
  }

  try {
    const pubKey = forge.pki.publicKeyFromPem(
      process.env.REACT_APP_PUBLIC_KEY_PASSWORD
    );
    const encrypted = pubKey.encrypt(toEncrypt, "RSA-OAEP", {
      md: forge.md.sha256.create(),
      mgf1: {
        md: forge.md.sha256.create()
      }
    });

    return btoa(encrypted);
  } catch (e) {
    console.log(process.env.REACT_APP_PUBLIC_KEY_PASSWORD);
    console.log(e);
    throw new Error(String(e));
  }
};
function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

export function formatDateWithUnderscore(date: Date) {
  return (
    [
      date.getUTCFullYear(),
      padTo2Digits(date.getUTCMonth() + 1),
      padTo2Digits(date.getUTCDate())
    ].join("") +
    "_" +
    [
      padTo2Digits(date.getUTCHours()),
      padTo2Digits(date.getUTCMinutes()),
      padTo2Digits(date.getUTCSeconds())
    ].join("")
  );
}

export function formatStringToDateTime(
  date: Maybe<string> | undefined | null | string | number
): string {
  if (!date) {
    return "";
  }
  const dateObject = new Date(date);

  return (
    [
      padTo2Digits(dateObject.getDate()),
      padTo2Digits(dateObject.getUTCMonth() + 1),
      dateObject.getFullYear()
    ].join("/") +
    " " +
    padTo2Digits(dateObject.getHours()) +
    ":" +
    padTo2Digits(dateObject.getMinutes())
  );
}

export function formatFileSize(size: number): string {
  if (!size) {
    return "";
  }
  var fSExt = ["Bytes", "KB", "MB", "GB"];
  let i = 0;
  while (size > 900) {
    size /= 1024;
    i++;
  }
  return Math.round(size * 100) / 100 + " " + fSExt[i];
}

export function secondsOfDateFromNow(date: string): number {
  const date1 = new Date(date + " GMT");
  const date2 = new Date();
  return Math.round((Number(date2) - Number(date1)) / 1000);
}

export function replaceLast(find: string, replace: string, string: string) {
  const lastIndex = string.lastIndexOf(find);

  if (lastIndex === -1) {
    return string;
  }

  const beginString = string.substring(0, lastIndex);
  const endString = string.substring(lastIndex + find.length);

  return beginString + replace + endString;
}

export const actionTypeOrder = ["Input", "Output", "Describing", "Script"];

export const capitalizeCase = (word: string): string => {
  const text = word
    .toLowerCase()
    .split(" ")
    .map((e) => e.charAt(0).toUpperCase() + e.substring(1))
    .join(" ");
  return text;
};

// This pricing (in dollar) needs to be removed in the future -> Pricing can change and is different for os
export const priceCalculationPerHour = (key: string): number => {
  let price = 0;
  switch (key) {
    case "t3.small":
      price = 0.0424;
      break;
    case "t3.medium":
      price = 0.0664;
      break;
    case "m6a.large":
      price = 0.1955;
      break;
    case "m6a.xlarge":
      price = 0.391;
      break;
    case "m6a.2xlarge":
      price = 0.782;
      break;
    case "z1d.3xlarge":
      price = 1.902;
      break;
    case "m5a.large":
      price = 0.196;
      break;
    case "m5a.xlarge":
      price = 0.392;
      break;
    case "m5a.2xlarge":
      price = 0.784;
      break;
    case "m5a.4xlarge":
      price = 1.568;
      break;
    case "c6a.large":
      price = 0.1793;
      break;
    case "c6a.xlarge":
      price = 0.3586;
      break;
    case "c6a.2xlarge":
      price = 0.7172;
      break;
    case "c6a.4xlarge":
      price = 1.4344;
      break;
    case "c6a.8xlarge":
      price = 2.8688;
      break;
    case "r6a.large":
      price = 0.2288;
      break;
    case "r6a.xlarge":
      price = 0.4576;
      break;
    case "r6a.2xlarge":
      price = 0.9152;
      break;
    case "r6a.4xlarge":
      price = 1.8304;
      break;
    case "r6a.8xlarge":
      price = 3.6608;
      break;
  }

  return price;
};

export function generateRandom() {
  let length = 8,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

export const MAX_COMMENT_SIZE = 100000;
