import { gql, useMutation } from "@apollo/client";

export const MUTATION_UPDATE_ENVIRONMENT = gql`
  mutation updateEnvironmentStatus(
    $environmentId: ID!
    $environmentStatusChange: EnvironmentStatusChange!
  ) {
    updateEnvironmentStatus(
      input: {
        environmentId: $environmentId
        environmentStatusChange: $environmentStatusChange
      }
    )
  }
`;

export function useUpdateEnvironmentStatusMutation() {
  const [
    updateEnvironmentStatus,
    {
      loading: isLoadingUpdateEnvironmentStatus,
      error: errorLoadingUpdateEnvironmentStatus,
      data: updateEnvironmentStatusData
    }
  ] = useMutation(MUTATION_UPDATE_ENVIRONMENT, {
    context: { clientName: "interactive" }
  });

  return {
    updateEnvironmentStatus,
    isLoadingUpdateEnvironmentStatus,
    errorLoadingUpdateEnvironmentStatus,
    updateEnvironmentStatusData
  };
}
