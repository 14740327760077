import { gql, useLazyQuery } from "@apollo/client";

export const COMPARE_REPO_OBJECTS = gql`
  query getInteractiveFileComparison(
    $environmentId: ID!
    $repoName: String!
    $path: String!
  ) {
    getInteractiveFileComparison(
      input: { environmentId: $environmentId, repoName: $repoName, path: $path }
    ) {
      comparison
      versionId
    }
  }
`;

export function useCompareRepoObjectQuery() {
  const [
    getInteractiveFileComparison,
    {
      data: interactiveFileComparison,
      loading: isLoadingFileComparison,
      error: errorFileComparison
    }
  ] = useLazyQuery(COMPARE_REPO_OBJECTS, {
    context: { clientName: "interactive" },
    fetchPolicy: "no-cache"
  });

  return {
    getInteractiveFileComparison,
    interactiveFileComparison:
      interactiveFileComparison?.getInteractiveFileComparison,
    isLoadingFileComparison,
    errorFileComparison
  };
}
