import { gql, useLazyQuery } from "@apollo/client";
export const ExecutionImageRecommendations = gql`
  query getExecutionImageRecommendations(
    $environmentId: ID!
    $repoName: String!
    $path: String!
  ) {
    getExecutionImageRecommendations(
      input: { environmentId: $environmentId, repoName: $repoName, path: $path }
    ) {
      images
    }
  }
`;

export function useQueryGetExecImageRecommendations() {
  const [
    getExecutionImageRecommendations,
    {
      data: executionImageRecommendations,
      loading: isLoadingExecutionImageRecommendations,
      error: errrorExecutionImageRecommendations
    }
  ] = useLazyQuery(ExecutionImageRecommendations, {
    fetchPolicy: "no-cache",
    context: { clientName: "interactive" }
  });
  return {
    getExecutionImageRecommendations,
    execImageRecommendations: executionImageRecommendations?.images,
    isLoadingExecutionImageRecommendations,
    errrorExecutionImageRecommendations
  };
}
