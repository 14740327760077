import { useState, useEffect } from "react";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import {
  BpCheckbox,
  StyledTreeItem,
  getFlattenedTree
} from "../../helpers/treeHelper";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import FolderIcon from "@mui/icons-material/Folder";
import FileIcon from "../abstractComponents/fileIcon";

export function InteractivePartialImport(props: {
  setSelectedFileImport: Function;
  selectedFileImport: string[];
  tree: any;
}) {
  const [flattenTree, setFlattenTree] = useState<any[]>([]);

  const renderTreeNodesInteractivePartialImport = (
    data: any,
    checkedFolders: Array<string>,
    setCheckedFolders: Function,
    repoName: string = "",
    flattenTree: any[] = []
  ) =>
    data.map((item: any) => {
      let isChecked =
        checkedFolders.includes(repoName + "/" + item.absolutePath) ||
        checkedFolders.includes(
          repoName + "/" + item.absolutePath?.slice(0, -1)
        );

      const getChildFiles = (
        repoName: string,
        directoryPath: string
      ): any[] => {
        return flattenTree
          .filter(
            (file) =>
              file.repo?.name === repoName &&
              file.absolutePath.startsWith(directoryPath + "/") &&
              !checkedFolders.includes(repoName + "/" + file.absolutePath)
          )
          .map((file) =>
            file.isDir
              ? repoName + "/" + file.absolutePath.slice(0, -1)
              : repoName + "/" + file.absolutePath
          );
      };

      const label = item.isDir ? (
        <>
          <BpCheckbox
            aria-label="Select Folder"
            checked={isChecked}
            sx={{ "& .MuiSvgIcon-root": { fontSize: 15 } }}
            className="folder_select_checkbox"
            onClick={(event) => {
              if (
                checkedFolders.includes(
                  repoName + "/" + item.absolutePath?.slice(0, -1)
                )
              ) {
                setCheckedFolders(
                  checkedFolders.filter(
                    (folderName: string) =>
                      !`${folderName}/`.includes(
                        repoName + "/" + item.absolutePath
                      )
                  )
                );
              } else {
                setCheckedFolders(
                  [
                    ...checkedFolders,
                    getChildFiles(repoName, item.absolutePath.slice(0, -1))
                  ].flat()
                );
              }

              event.stopPropagation();
            }}
          />
          <FolderIcon className="grey_color folder_icon" />
          {item.name}
        </>
      ) : (
        <>
          {/* // optional selection on files */}
          <BpCheckbox
            aria-label="Select File"
            checked={isChecked}
            sx={{ "& .MuiSvgIcon-root": { fontSize: 15 } }}
            className="folder_select_checkbox"
            onClick={(event) => {
              if (
                checkedFolders.length > 0 &&
                checkedFolders.includes(repoName + "/" + item.absolutePath)
              ) {
                setCheckedFolders((prevState: any) => {
                  return prevState.filter(
                    (file: string) =>
                      file !== repoName + "/" + item.absolutePath
                  );
                });
              } else {
                setCheckedFolders([
                  ...checkedFolders,
                  repoName + "/" + item.absolutePath
                ]);
              }

              event.stopPropagation();
            }}
          />
          <FileIcon
            fileName={item.name}
            isValid={item.isValid}
            fontSize={"small"}
            absolutePath={item.absolutePath}
          />
          {item.name}
        </>
      );
      const nameVar =
        repoName +
        "__-*-__" +
        (item.absolutePath ? item.absolutePath : item.path);
      if (item.content && item.content.length > 0) {
        return (
          <StyledTreeItem itemId={nameVar} key={nameVar} label={label}>
            {renderTreeNodesInteractivePartialImport(
              item.content,
              checkedFolders,
              setCheckedFolders,
              repoName,
              flattenTree
            )}
          </StyledTreeItem>
        );
      }

      return <StyledTreeItem itemId={nameVar} key={nameVar} label={label} />;
    });

  useEffect(() => {
    setFlattenTree(getFlattenedTree(props.tree));
  }, [props.tree]);

  return (
    <>
      {props.tree?.map((repo: any) => (
        <SimpleTreeView
          key={`${repo.repo.name}-index`}
          slots={{
            expandIcon: AddBoxOutlinedIcon,
            collapseIcon: IndeterminateCheckBoxOutlinedIcon
          }}
          defaultExpandedItems={["/"]}
          className={`mt-2 tree-view`}
          disableSelection
        >
          <StyledTreeItem
            key={"/"}
            itemId={"/"}
            label={
              <>
                <CenterFocusStrongIcon className="grey_color" />{" "}
                {repo.repo.name}
              </>
            }
          >
            {renderTreeNodesInteractivePartialImport(
              repo.repoObjects.sort(function (a: any, b: any) {
                if (a.isDir && !b.isDir) {
                  return -1;
                }
                if (b.isDir && !a.isDir) {
                  return 1;
                }

                return a.name.localeCompare(b.name);
              }),
              props.selectedFileImport,
              props.setSelectedFileImport,
              repo.repo.name,
              flattenTree
            )}
          </StyledTreeItem>
        </SimpleTreeView>
      ))}
    </>
  );
}
