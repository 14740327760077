export enum StopScheduleVariable {
  STANDARD = "19:00;mon,tue,wed,thu,fri",
  NONE = ""
}

export const getStopScheduleVariableKeyFromValue = (value: string | null) =>
  value
    ? Object.keys(StopScheduleVariable)[
        Object.values(StopScheduleVariable).indexOf(
          value as unknown as StopScheduleVariable
        )
      ]
    : "NONE";

export enum StopSchedule {
  STANDARD = "Stop at 19:00 (UTC) on weekdays",
  NONE = "No stop schedule"
}
export declare type StopScheduleType = keyof typeof StopSchedule;
export const getStopScheduleKeyFromValue = (value: string | null) =>
  value
    ? Object.keys(StopSchedule)[
        Object.values(StopSchedule).indexOf(value as unknown as StopSchedule)
      ]
    : "STANDARD";

export const getStopScheduleVariable = (value: string | null) =>
  value
    ? StopScheduleVariable[
        getStopScheduleKeyFromValue(value) as keyof typeof StopScheduleVariable
      ]
    : StopScheduleVariable.STANDARD;
