import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";

export const LIST_ENVIRONMENTS = gql`
  query listEnvironments(
    $activityId: ID!
    $environmentId: ID
    $limit: Int!
    $cursor: String
  ) {
    listEnvironments(
      input: {
        activityId: $activityId
        environmentId: $environmentId
        limit: $limit
        cursor: $cursor
      }
    ) {
      environments {
        __typename
        environmentId
        environmentName
        state
        instanceStatus
        systemStatus
        createdBy
        actionId
        createdAt
        image {
          imageId
          imageName
          platformDetails
          architecture
          createdAt
          software {
            name
            version
          }
        }
        instanceType {
          instanceTypeId
          cpu
          memory
          architectures
        }
        schedule
      }
      nextCursor
    }
  }
`;

export function useQueryEnvironmentsQuery(
  activityId: number,
  polling: boolean = true,
  environmentId?: string,
  limit: number = 50
) {
  const {
    data: environments,
    loading: isLoadingEnvironments,
    error: errorListEnvironments,
    refetch: refetchEnvironments,
    fetchMore: fetchMoreExecutionImages
  } = useQuery(LIST_ENVIRONMENTS, {
    variables: {
      activityId: activityId,
      ...(environmentId && { environmentId }),
      limit: limit
    },
    context: { clientName: "interactive" },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    ...(polling && { pollInterval: 100000 }) // Query data periodically every 100 sec to update status of the environments
  });

  const listMoreImages = (limit: number, nextCursor: string) =>
    fetchMoreExecutionImages({
      variables: {
        cursor: nextCursor
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        fetchMoreResult.listEnvironments.environments = [
          ...(prevResult?.listEnvironments?.environments
            ? prevResult.listEnvironments.environments
            : []),
          ...fetchMoreResult.listEnvironments.environments
        ];
        return fetchMoreResult;
      }
    });

  useEffect(() => {
    if (environments && environments.listEnvironments.nextCursor) {
      listMoreImages(limit, environments.listEnvironments.nextCursor);
    }
  }, [environments]);

  return {
    environments: environments?.listEnvironments?.environments || [],
    isLoadingEnvironments,
    errorListEnvironments,
    refetchEnvironments
  };
}
