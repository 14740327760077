import { gql, useMutation } from "@apollo/client";

export const MUTATION_UPDATE_ENVIRONMENT = gql`
  mutation updateEnvironmentSchedule($environmentId: ID!, $schedule: String!) {
    updateEnvironmentSchedule(
      input: { environmentId: $environmentId, schedule: $schedule }
    )
  }
`;

export function useUpdateEnvironmentScheduleMutation() {
  const [
    updateEnvironmentSchedule,
    {
      loading: isLoadingEnvironmentSchedule,
      error: errorEnvironmentSchedule,
      data: updateEnvironmentScheduleData
    }
  ] = useMutation(MUTATION_UPDATE_ENVIRONMENT, {
    context: { clientName: "interactive" }
  });

  return {
    updateEnvironmentSchedule,
    isLoadingEnvironmentSchedule,
    errorEnvironmentSchedule,
    updateEnvironmentScheduleData
  };
}
