import { gql, useLazyQuery } from "@apollo/client";
export const ExecutionInputRecommendations = gql`
  query getExecutionInputRecommendations(
    $environmentId: ID!
    $repoName: String!
    $path: String!
  ) {
    getExecutionInputRecommendations(
      input: { environmentId: $environmentId, repoName: $repoName, path: $path }
    ) {
      inputs {
        repoName
        path
      }
    }
  }
`;

export function useQueryGetExecInputRecommendations() {
  const [
    getExecutionInputRecommendations,
    {
      data: executionInputRecommendations,
      loading: isLoadingExecutionInputRecommendations,
      error: errrorExecutionInputRecommendations
    }
  ] = useLazyQuery(ExecutionInputRecommendations, {
    fetchPolicy: "no-cache",
    context: { clientName: "interactive" }
  });
  return {
    getExecutionInputRecommendations,
    execInputRecommendations: executionInputRecommendations?.inputs,
    isLoadingExecutionInputRecommendations,
    errrorExecutionInputRecommendations
  };
}
