import { gql, useMutation } from "@apollo/client";

export const MUTATION_IMPORT_FILES = gql`
  mutation import_files(
    $environmentId: ID!
    $importPaths: [InteractiveFileInput!]
  ) {
    importFiles(
      input: { environmentId: $environmentId, importPaths: $importPaths }
    )
  }
`;

export function useImportFilesMutation() {
  const [
    importFiles,
    {
      data: idImportFiles,
      loading: isLoadingImportFiles,
      error: errorImportFiles
    }
  ] = useMutation(MUTATION_IMPORT_FILES, {
    context: { clientName: "interactive" }
  });

  return {
    importFiles,
    idImportFiles,
    isLoadingImportFiles,
    errorImportFiles
  };
}
