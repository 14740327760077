import {
  Button,
  Col,
  Row,
  Spinner,
  Modal,
  Form,
  Table,
  ListGroup
} from "react-bootstrap";
import Error from "../abstractComponents/error";
import { useState, useEffect, useContext } from "react";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { BrowsePmxActivityContext } from "../../contexts/browsePmxActivityProvider";
import { useExportFilesMutation } from "../../backend/hooks/interactive/mutationExportFiles";
import {
  ActionType,
  getActionTypeKeyFromValue,
  ActionTypeType,
  capitalizeCase
} from "../../helpers/stringHelper";
import { GetRepoObjectQueryData, RepoObject } from "../../backend/types";
import ErrorBoundary from "../abstractComponents/errorBoundary";
import { TreeViewListFilesInRepo } from "../pmxActivity/treeViewListFilesInRepo";
import { shortenStringMiddle } from "../../helpers/stringHelper";
import UserManualLink from "../abstractComponents/usermanual";

const ALLOWED_EXTERNAL_ACTIONS_KEYS = [
  "UPLOAD",
  "ADF_CREATION",
  "PLOT_CREATION",
  "REPORT_CREATION",
  "MODEL_EXECUTION",
  "OTHER_ACTION"
];

type ActionFile = {
  path: string;
  status: string;
};

const FILE_TYPES = ["Input", "Output", "Describing"];

export function InteractiveFileTransferImportAction(props: {
  environmentId: string;
  currentSubArray: any;
  selectedRows: string[];
}) {
  const { treeHierarchyGlobal, repos } = useContext(BrowsePmxActivityContext);

  const { exportFiles, isLoadingExportFiles, errorExportFiles } =
    useExportFilesMutation();

  const [isActionModalVisible, setIsActionModalVisible] = useState(false);
  const [actionType, setActionType] = useState(ActionType.UPLOAD);
  const [description, setDescription] = useState("");
  const [filesToBeDeleted, setFilesToBeDeleted] = useState<any[]>([]);
  const [currentSelectedPathTree, setCurrentSelectedPathTree] = useState("");
  const [currentSelectedRepoIdTree, setCurrentSelectedRepoIdTree] = useState<
    number | null
  >(null);
  const [actionFilesList, setActionFilesList] = useState<any[]>([]);
  const [philFileList, setPhilFileList] = useState<any[]>([]);

  function changeActionType(event: React.ChangeEvent<HTMLSelectElement>) {
    event.preventDefault();
    setActionType(ActionType[event.target.value as ActionTypeType]);
    if (event.target.value === "UPLOAD") {
      actionFilesList.forEach((actionFile: ActionFile) => {
        actionFile.status = "Output";
      });
      setActionFilesList([...actionFilesList]);
    }
  }

  useEffect(() => {
    setFilesToBeDeleted(
      props.currentSubArray.filter(
        (diffObject: any) =>
          diffObject.state === "DELETED" &&
          props.selectedRows.indexOf(diffObject.path) !== -1
      )
    );

    const uploadedFiles = props.currentSubArray.filter(
      (diffObject: any) =>
        (diffObject.state === "MODIFIED" || diffObject.state === "ADDED") &&
        props.selectedRows.indexOf(diffObject.path) !== -1
    );
    setActionFilesList(
      uploadedFiles.map((diffObject: any) => ({
        path: diffObject.fileName,
        status: "Output"
      }))
    );
  }, [props.selectedRows]);

  const performActionUpload = () => {
    exportFiles({
      variables: {
        environmentId: props.environmentId,
        exportPaths: actionFilesList.map((diffObject: any) => ({
          actionFileType: diffObject.status,
          path: diffObject.path
        })),
        deletePaths: filesToBeDeleted.map((diffObject: any) => {
          return diffObject.fileName;
        }),
        additionalFiles: philFileList.map((diffObject: any) => ({
          versionId: diffObject.versionId,
          actionFileType: diffObject.status
        })),
        qualityCheckStatus: null,
        description: description,
        actionType: getActionTypeKeyFromValue(actionType)
      }
    }).then(() => {
      setIsActionModalVisible(false);
    });
  };

  const onNodeSelectFunction = (path: string, repoId: number) => {
    setCurrentSelectedPathTree(path);
    setCurrentSelectedRepoIdTree(repoId);
  };

  const getActionFileStatus = (path: string) => {
    return actionFilesList.find(
      (actionFile: ActionFile) => path === actionFile.path
    )?.status;
  };

  const changeActionFileStatus = (status: string, path: string) => {
    const foundFile = actionFilesList.find(
      (actionFile: ActionFile) => path === actionFile.path
    );
    foundFile.status = status;
    setActionFilesList([...actionFilesList]);
  };

  const getPhilFileStatus = (versionId: string) => {
    return philFileList.find(
      (philFile: any) => versionId === philFile.versionId
    )?.status;
  };

  const changePhilFileStatus = (status: string, versionId: string) => {
    const foundFile = philFileList.find(
      (philFile: any) => versionId === philFile.versionId
    );
    foundFile.status = status;
    setPhilFileList([...philFileList]);
  };

  const removeFromPhilFileList = (versionId: string) => {
    setPhilFileList([
      ...philFileList.filter(
        (philFile: any) => versionId !== philFile.versionId
      )
    ]);
  };

  const assignPhilFileToPhil = () => {
    const correctRepo = repos.find(
      (repoData: any) => repoData.repo.id === currentSelectedRepoIdTree
    );

    const correctFile = correctRepo.repoObjects.find(
      (file: RepoObject) => file.name === currentSelectedPathTree
    );

    if (
      !philFileList.find(
        (philFile: any) => correctFile.versionId === philFile.versionId
      )
    ) {
      setPhilFileList([
        ...philFileList,
        {
          ...correctFile,
          absolutePath: correctRepo.repo.name + "/" + correctFile.name,
          status: "Input"
        }
      ]);
    }
  };

  return (
    <>
      <Button
        id="proced_with_import_import_action"
        className="button-secondary float-start ms-2"
        variant="primary"
        onClick={() => {
          setIsActionModalVisible(true);
        }}
      >
        <DriveFolderUploadIcon /> Action Upload
      </Button>
      <Modal
        show={isActionModalVisible}
        onHide={() => {
          setIsActionModalVisible(false);
        }}
        dialogClassName="modal-80w interactive_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Importing files from Interactive into PHIL</Modal.Title>
          <UserManualLink
            url="/usermanual/datatransfer/#action-upload"
            testId="um-ie-action-upload"
            className="align-self-start ms-1"
          />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={3}>
              <Form.Label>Action Type</Form.Label>
              <Form.Select
                value={getActionTypeKeyFromValue(actionType)}
                onChange={changeActionType}
                key="adf_action_type"
                id="adfActionType"
              >
                {Object.entries(ActionType)
                  .filter((element) =>
                    ALLOWED_EXTERNAL_ACTIONS_KEYS.includes(element[0])
                  )
                  .map((element) => (
                    <option key={element[0]} value={element[0]}>
                      {element[1]}
                    </option>
                  ))}
              </Form.Select>
            </Col>
            <Col xs={3}></Col>
            <Col xs={6}>
              <Form.Group className="mb-3" controlId="upload_description">
                <Form.Label>
                  Upload Description <span className="red_form">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  placeholder="Please enter a description"
                  value={description}
                  disabled={isLoadingExportFiles}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          {isLoadingExportFiles && (
            <Row className="loading_row">
              <Col className="text-center">
                <Spinner animation="border" className="spinner_color" />
                <br />
                Loading...
              </Col>
            </Row>
          )}
          <br />
          <br />
          <Row className="action_files">
            <Col xs={7}>
              <h3 className="font-weight-normal">Action Files:</h3>
              <ListGroup>
                {actionFilesList.map((actionFile: ActionFile) => (
                  <ListGroup.Item key={actionFile.path}>
                    <Row>
                      <Col xs={10} title={actionFile.path}>
                        {shortenStringMiddle(actionFile.path, 60)}
                      </Col>
                      <Col xs={2}>
                        <Form.Select
                          value={getActionFileStatus(actionFile.path)}
                          onChange={(
                            event: React.ChangeEvent<HTMLSelectElement>
                          ) => {
                            event.preventDefault();
                            changeActionFileStatus(
                              event.target.value,
                              actionFile.path
                            );
                          }}
                          className="float-end change_file_status"
                          disabled={actionType === ActionType.UPLOAD}
                        >
                          {FILE_TYPES.map((element) => (
                            <option key={element} value={element}>
                              {element}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}

                {philFileList.map((philFile: any) => (
                  <ListGroup.Item
                    className="phil_action_upload_div"
                    key={philFile.versionId}
                  >
                    <Row>
                      <Col
                        xs={9}
                        className="phil_action_upload_div_inner"
                        title={philFile.absolutePath}
                      >
                        {shortenStringMiddle(philFile.absolutePath, 50)}
                      </Col>
                      <Col xs={3}>
                        <Form.Select
                          value={getPhilFileStatus(philFile.versionId)}
                          onChange={(
                            event: React.ChangeEvent<HTMLSelectElement>
                          ) => {
                            event.preventDefault();
                            changePhilFileStatus(
                              event.target.value,
                              philFile.versionId
                            );
                          }}
                          className="float-start change_phil_file_status"
                        >
                          <option key={"Input"} value={"Input"}>
                            Input
                          </option>
                          <option key={"Describing"} value={"Describing"}>
                            Describing
                          </option>
                        </Form.Select>
                        <Button
                          onClick={() => {
                            removeFromPhilFileList(philFile.versionId);
                          }}
                          variant="link"
                          className="phil_remove_file float-end"
                        >
                          X
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
            <Col xs={1} className="align-self-center">
              <Button
                variant="outline-primary"
                onClick={assignPhilFileToPhil}
                disabled={
                  currentSelectedPathTree.endsWith("/") ||
                  currentSelectedPathTree === ""
                }
                id="link_files"
              >
                {"<<"} Link
              </Button>
            </Col>
            <Col xs={4}>
              <h3 className="font-weight-normal">
                Additional Unchanged Files:
              </h3>

              {treeHierarchyGlobal?.map((repoQuery: GetRepoObjectQueryData) => (
                <ErrorBoundary key={repoQuery.repo.id}>
                  <TreeViewListFilesInRepo
                    repo={repoQuery}
                    onNodeSelect={onNodeSelectFunction}
                    currentSelectedPath={currentSelectedPathTree}
                    currentSelectedRepoId={currentSelectedRepoIdTree}
                    setCurrentSelectedRepoId={setCurrentSelectedRepoIdTree}
                  />
                </ErrorBoundary>
              ))}
            </Col>
          </Row>
          <br />
          <br />
          <h3 className="font-weight-normal">Preview - Resulting Actions:</h3>
          <Table
            striped
            bordered
            hover
            size="sm"
            className="resulting_action_preview"
          >
            <thead>
              <tr>
                <th>Action Type</th>
                <th>Files</th>
              </tr>
            </thead>
            <tbody>
              {/*************** Delete *******************/}
              {filesToBeDeleted.length > 0 && (
                <tr>
                  <td rowSpan={filesToBeDeleted.length} valign="middle">
                    Hide
                  </td>
                  <td className="resulting_action_preview_div">
                    {filesToBeDeleted[0].path}
                  </td>
                </tr>
              )}
              {filesToBeDeleted.map((file: any, index: number) => {
                if (index === 0) {
                  return <></>;
                } else {
                  return (
                    <tr key={`delete_${index}`}>
                      <td>{file.path}</td>
                    </tr>
                  );
                }
              })}
              {/*************** Upload *******************/}

              {actionFilesList.filter(
                (actionFile: ActionFile) => actionFile.status === "Input"
              ).length > 0 && (
                <tr>
                  <td
                    rowSpan={
                      actionFilesList.filter(
                        (actionFile: ActionFile) =>
                          actionFile.status === "Input"
                      ).length
                    }
                    valign="middle"
                  >
                    Upload
                  </td>
                  <td className="resulting_action_preview_div">
                    {
                      actionFilesList.filter(
                        (actionFile: ActionFile) =>
                          actionFile.status === "Input"
                      )[0].path
                    }
                    <span className="float-end">Output</span>
                  </td>
                </tr>
              )}
              {actionFilesList
                .filter(
                  (actionFile: ActionFile) => actionFile.status === "Input"
                )
                .map((file: any, index: number) => {
                  if (index === 0) {
                    return <></>;
                  } else {
                    return (
                      <tr key={`file_upload_${index}`}>
                        <td>
                          {file.path}
                          <span className="float-end">Output</span>
                        </td>
                      </tr>
                    );
                  }
                })}

              {/*************** Action Upload *******************/}
              {actionFilesList.length > 0 && (
                <tr>
                  <td
                    rowSpan={actionFilesList.length + philFileList.length}
                    valign="middle"
                  >
                    {capitalizeCase(
                      String(getActionTypeKeyFromValue(actionType))
                    )}
                  </td>
                  <td className="resulting_action_preview_div">
                    {actionFilesList[0].path}
                    <span className="float-end">
                      {actionFilesList[0].status}
                    </span>
                  </td>
                </tr>
              )}
              {actionFilesList.map((file: any, index: number) => {
                if (index === 0) {
                  return <></>;
                } else {
                  return (
                    <tr key={`action_upload_${index}}`}>
                      <td>
                        {file.path}
                        <span className="float-end">{file.status}</span>
                      </td>
                    </tr>
                  );
                }
              })}
              {philFileList.map((file: any, index: number) => (
                <tr key={`phil_file_${index}`}>
                  <td className="interactive_phil_file_td">
                    {file.name}
                    <span className="float-end">{file.status}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setIsActionModalVisible(false);
            }}
            disabled={isLoadingExportFiles}
          >
            Close
          </Button>
          <Button
            variant="primary"
            disabled={description === "" || isLoadingExportFiles}
            onClick={performActionUpload}
          >
            Perform Actions
          </Button>
        </Modal.Footer>
      </Modal>
      {errorExportFiles && <Error error={errorExportFiles} />}
    </>
  );
}
