import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import NavBarPmxActivity from "../pmxActivity/navbarPmxActivity";
import { CreateJob } from "../abstractComponents/createJob";
import { useQueryEnvironmentsQuery } from "../../backend/hooks/interactive/queryListEnvironments";
import { useQueryListInteractiveFilesQuery } from "../../backend/hooks/interactive/queryListInteractiveFiles";
import { InteractiveFile } from "../../backend/interactiveTypes";
import getTreeHierarchyFromRepoObjectList from "../../helpers/treeHelper";
import { GetRepoObjectQueryData } from "../../backend/types";
import { ApolloError } from "@apollo/client/errors";
export function InteractiveCreateJob() {
  const { activityId, activityData } = useContext(PmxActivityDetailsContext);
  const environmentId = (useParams() as { environmentId: string })
    .environmentId;

  const { environments, isLoadingEnvironments, errorListEnvironments } =
    useQueryEnvironmentsQuery(activityId, false, environmentId);
  const {
    getListInteractiveFiles,
    interactiveFiles,
    isLoadingInteractiveFiles,
    errorLoadingInteractiveFiles
  } = useQueryListInteractiveFilesQuery();

  const getAllRepos = () => {
    let repos = [
      {
        name: activityData?.mainRepository.name,
        id: activityData?.mainRepository.id,
        isReadOnlyRepository: false
      }
    ];
    if (
      activityData?.readOnlyRepositories &&
      activityData.readOnlyRepositories.length > 0
    ) {
      activityData.readOnlyRepositories.forEach((repo) => {
        repos.push({
          name: repo?.name ?? "",
          id: repo?.id ?? "",
          isReadOnlyRepository: true
        });
      });
    }
    return repos;
  };

  const castingFilesToRepObjectTree = () => {
    let updateObject: GetRepoObjectQueryData[] = [];
    const repos = getAllRepos();
    const repoObjectsMap: any = {};
    const folderStructure: any = [];

    interactiveFiles?.forEach((file: InteractiveFile) => {
      if (!repoObjectsMap[file.repoName]) {
        repoObjectsMap[file.repoName] = [];
      }
      repoObjectsMap[file.repoName].push({
        name: file.path,
        isDir: false
      });

      const subFolders = file.path.split("/");
      subFolders.pop(); // Remove the file name from the array
      let key = "";
      subFolders.forEach((sub) => {
        key += sub + "/";
        if (!folderStructure.includes(key)) {
          repoObjectsMap[file.repoName].push({
            name: key,
            isDir: true
          });
          folderStructure.push(key);
        }
      });
    });
    Object.keys(repoObjectsMap).forEach((repoName) => {
      updateObject.push({
        repo: {
          name: repoName,
          id: repos.find((repo) => repo.name === repoName)?.id
        },
        repoObjects: repoObjectsMap[repoName],
        isReadOnlyRepository: repos.find((repo) => repo.name === repoName)
          ?.isReadOnlyRepository
      } as unknown as GetRepoObjectQueryData);
    });
    return getTreeHierarchyFromRepoObjectList(updateObject);
  };

  const treeSelection = {
    interactiveTreeSelection: castingFilesToRepObjectTree(),
    environmentId: environmentId,
    getFilesForSelection: getListInteractiveFiles
  };

  return (
    <>
      <NavBarPmxActivity activeIndex={1} />

      <div className="app_content pmx_app_content">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          className="float-start pmx_bradcrumbs"
        >
          <Link to={`/activity/${activityId}`}>
            {activityData?.trialNumber}
          </Link>
          <Link to={`/activity/${activityId}/interactive`}>
            Interactive Environments
          </Link>
          <Link to={`/activity/${activityId}/interactive/${environmentId}`}>
            {environments && environments.length > 0
              ? environments[0].environmentName
              : ""}
          </Link>
          <Typography>Create Execution Job</Typography>
        </Breadcrumbs>
        <br />
        <hr />
        <CreateJob
          parentLoading={[isLoadingInteractiveFiles, isLoadingEnvironments]}
          parentError={
            [
              errorLoadingInteractiveFiles,
              errorListEnvironments
            ] as ApolloError[]
          }
          treeSelection={treeSelection}
        />
      </div>
    </>
  );
}
